.section-2__bg {
  position: absolute;
  z-index: -1;
}

@media (max-width: 1126px) {
  .section-2__bg {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .section-2__bg {
    display: none;
  }
}