.section-1__button {
  grid-area: button;
  width: 210px;
  height: 52px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

@media (max-width: 768px) {
  .section-1__button {
    width: 195px;
    height: 48px;
    font-size: 18px;
    line-height: 24px;
  }
}