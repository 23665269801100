.section-2__text {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 36px;
  max-width: 600px;
}

@media (max-width: 768px) {
  .section-2__text {
    font-size: 16px;
    line-height: 24px;
    max-width: 342px;
  }
}