.section-1 {
  display: grid;
  margin: 0;
  padding: 106px 0 206px 80px;
  position: relative;
  grid-template-columns: 1fr 0.1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
    "title ."
    "text ."
    "button .";
  z-index: 1;
}

@media (max-width: 768px) {
  .section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
}