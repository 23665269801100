.button {
  border: 1px solid #D2FA5A;
  border-radius: 32px;
  background: transparent;
  color: #D2FA5A;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button:hover, .button:active, .button_active {
  background: #D2FA5A;
  color: black;
}
