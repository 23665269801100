.cards__img {
  align-self: flex-end;
  width: 549px;
  height: 628px;
}

@media (max-width: 900px) {
  .cards__img {
    align-self: center;
    height: 568px;
    width: 349px;
    order: 5;
  }
}