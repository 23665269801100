.accordion__answer {
  margin: 0;
  padding: 0 0 0 29px;
  width: 771px;
  border-bottom: 1px solid #194D44
}

.accordion__answer p {
  margin: 0 0 24px;
  padding: 0;
}

.accordion__answer {
  display: none;
}

@media (max-width: 768px) {
  .accordion__answer {
    width: 290px;
    font-size: 16px;
    line-height: 24px;
  }
  .accordion__answer p {
    margin: 0 0 24px;
    padding: 0;
    max-width: 300px;
  }
}