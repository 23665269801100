.section-4__button {
  height: 48px;
  width: 100%;
  font-size: 20px;
  line-height: 32px;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .section-4__button {
    width: auto;
    font-size: 16px;
    line-height: 24px;
  }
}