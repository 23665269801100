@font-face {
  font-family: Steinbeck;
  src: url("The Temporary State - Steinbeck-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Uncut Sans";
  src: url("Uncut-Sans-Regular.woff2") format("woff2");
  src: url("Uncut-Sans-Regular.woff") format("woff");
  src: url("Uncut-Sans-Regular.otf") format("otf");
  font-weight: 400;
}

@font-face {
  font-family: "Uncut Sans Medium";
  src: url("Uncut-Sans-Medium.woff2") format("woff2");
  src: url("Uncut-Sans-Medium.woff") format("woff");
  src: url("Uncut-Sans-Regular.otf") format("otf");
  font-weight: 500;
}