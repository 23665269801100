.reviews__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 721px;
  height: 800px;
  overflow-y: auto;
  padding: 50px 0;
}

.reviews__wrapper::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .reviews__wrapper {
    width: 342px;
    gap: 8px;
    justify-content: center;
    height: 632px;
  }
}