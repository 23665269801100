.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #E4D7CD;
  color: #194D44;
  padding: 56px 0;
}

@media (max-width: 768px) {
  .faq {
    padding: 48px 0;
  }
}