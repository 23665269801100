.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #E4D7CD;
  padding-top: 16px;
  
}

@media (max-width: 900px) {
  .footer {
    display: block;
    position: relative;
  }
}