.accordion__item{
  margin: 0;
  padding: 0;
  align-self: center;
  max-width: 800px;
}

.accordion__item:hover{
  cursor: pointer;
}

.accordion__item.active .accordion__answer {
  display: block;
}

.accordion__item.active .accordion__question {
  border: none;
}

.accordion__item.active .accordion__icon {
  background: url(../../../images/minus.svg) no-repeat center;
  width: 24px;
  height: 24px;
  margin: 24px 0;
}

@media (max-width: 768px) {
  .accordion__item {
    max-width: 350px;
  }
}