.section-4__text {
  max-width: 480px;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  height: 242px;
}

@media (max-width: 917px) {
  .section-4__text {
    height: 150px;
  }
}

@media (max-width: 768px) {
  .section-4__text {
    text-align: center;
    max-width: 342px;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 24px;
  }
}