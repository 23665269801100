.footer__text {
  text-decoration: none;
  align-self: center;
  color: #194D44;
  text-align: center;
  margin: 2px 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}

.footer__text:visited {
  color: #194D44;
}

.footer__text:last-child {
  margin-bottom: 35px;
}