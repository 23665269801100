.footer__img {
  width: 364px;
  height: 216px;
}

@media (max-width: 900px) {
  .footer__img {
    position: absolute;
    width: 153px;
    height: 88px;
    z-index: 0;
  }
  .footer__img:first-child {
    left: 0;
    bottom: 0;
  }
  .footer__img:last-child {
    right: 0;
    bottom: 0;
  }
}