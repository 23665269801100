.section-4__title {
  font-family: Steinbeck, Helvetica, Arial, sans-serif;
  font-size: 40px;
  line-height: 48px;
  margin: 94px 0 24px 0;
  padding: 0;
}

@media (max-width: 768px) {
  .section-4__title {
    text-align: center;
    margin: 48px 0 32px 0;
    font-size: 32px;
    line-height: 32px;
  }
}