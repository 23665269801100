.button__link {
  display: block;
  margin: 0;
  padding: 4% 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #D2FA5A;
}

.button__link:visited {
  color: #D2FA5A;
}

.button__link:hover, .button__link:active {
  color: #000;
}

@media (max-width: 768px) {
  .button__link {
    font-size: 18px;
    line-height: 24px;
    padding: 5% 0;
  }
}