.section-3 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 120px;
  padding: 80px 0 104px;
  background-color: #194D44;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .section-3 {
    gap: 24px;
    padding: 64px 24px;
  }
}