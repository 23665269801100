.section-1__text {
  grid-area: text;
  max-width: 420px;
  margin: 16px 0 32px;
  padding: 0;
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 768px) {
  .section-1__text {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    max-width: 300px;
  }
}