.section-4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 56px 80px;
  flex-wrap: wrap;
}

@media (max-width: 917px) {
  .section-4 {
  gap: 24px;
  }
}

@media (max-width: 768px) {
  .section-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
    margin: 0;
    padding: 0 20px 52px 20px;
  }
}