.header__button {
  width: 133px;
  height: 36px;
  font-size: 18px;
  line-height: 20px;
  z-index: 10;
  position: fixed;
  top: 32px;
  right: 80px
}

@media (max-width: 768px) {
  .header__button {
    position: static;
  }
}