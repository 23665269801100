.section-2__bg_mobile-1 {
  opacity: 0;
}

@media (max-width: 768px) {
  .section-2__bg_mobile-1 {
    background: url(./../../../images/section-2__bg_mobile-1.png) no-repeat center;
    background-size: contain;
    opacity: 1;
    position: relative;
    z-index: -1;
    width: 390px;
    height: 352px;
  }
}