.review-card {
  background: white;
  border-radius: 20px;
  padding: 20px 24px;
  max-width: 480px;
  z-index: 1;
  list-style: none;
}

.review-card:nth-child(odd) {
  align-self: flex-start;
}

.review-card:nth-child(even) {
  align-self: flex-end;
}

@media (max-width: 768px) {
  .review-card:first-child {
    margin-top: 650px;
  }
  .review-card {
    box-sizing: border-box;
    max-width: 342px;
    min-width: 342px;
  }
}