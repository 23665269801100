.section-2 {
  margin: 0 auto;
  padding: 386px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  overflow: hidden;
}

@media (max-width: 768px) {
  .section-2 {
    padding: 16px 0;
  }
}
