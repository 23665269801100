.footer__link {
  text-decoration: none;
  align-self: center;
  color: #194D44;
  text-align: center;
  margin: 2px 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}

.footer__link:hover {
  opacity: 0.5;
}

.footer__link:visited {
  color: #194D44;
}