.cards__bg {
  background: url('../../../images/cards__bg.png') repeat top;
  width: 99.99vw;
  height: 813px;
  mix-blend-mode: soft-light;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 900px) {
  .cards__bg {
    height: 874px;
  }
}