.cards__text {
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 40px;
  padding: 0;
  max-width: 360px;
  color: #E4D7CD;
}

@media (max-width: 900px) {
  .cards__text {
    text-align: center;
    max-width: 275px;
    font-size: 16px;
    line-height: 24px;
    order: 3;
  }
}