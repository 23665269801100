.review-card__text {
  font-size: 20px;
  line-height: 28px;
  margin: 16px 0 0 0;
  padding: 0;
  max-width: 400px;
  color: #222222;
}

@media (max-width: 768px) {
  .review-card__text {
    font-size: 16px;
    line-height: 24px;
    max-width: 294px;
    margin: 8px 0 0 0;
  }
}