.reviews__title {
  font-family: "Uncut Sans Medium", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  margin: 0;
  padding: 56px 0 10px;
  color: #194D44;
}

@media (max-width: 768px) {
  .reviews__title {
    font-size: 24px;
    line-height: 32px;
    padding: 40px 0 10px;
  }
}