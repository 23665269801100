.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 80px;
}

@media (max-width: 768px) {
  .header {
    padding: 0 20px;
    height: 68px;
  }
}