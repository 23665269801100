.faq__title {
  font-family: "Uncut Sans Medium", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  margin: 0;
  padding: 0 0 48px;
}

@media (max-width: 768px) {
  .faq__title {
    font-size: 24px;
    line-height: 32px;
    max-width: 298px;
    padding: 0 0 32px;
  }
}