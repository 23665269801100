.section-4__buttons {
  display: flex;
  gap: 8px;
  margin: 0 0 32px 0;
  padding: 0;
}

@media (max-width: 768px) {
  .section-4__buttons {
    margin: 0 0 24px 0;
    max-width: 320px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}