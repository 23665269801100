.cards__logo {
  width: 215px;
  height: 95px;
  margin: 148px 0 35px 0;
}

@media (max-width: 900px) {
  .cards__logo {
    width: 129px;
    height: 56px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 64px 0 26px;
    order: 1;
  }
}