.review__shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(180deg, rgba(228, 215, 205, 0) 0%, #E4D7CD 100%);
  z-index: 2;
}

.review__shadow_top {
  position: absolute;
  left: 0;
  top: 110px;
  height: 100px;
  width: 100%;
  background: linear-gradient(180deg, #E4D7CD 0%, rgba(228, 215, 205, 0) 100%);
  z-index: 2;
}

@media (max-width: 768px) {
  .review__shadow_top {
    top: 96px;
  }
  .review__shadow {
    bottom: 15px;
  }
}