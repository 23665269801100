.cards__title {
  font-family: Steinbeck, Helvetica, Arial, sans-serif;
  font-size: 40px;
  line-height: 48px;
  margin: 0 0 16px 0;
  padding: 0;
  color: #E4D7CD;
}

@media (max-width: 900px) {
  .cards__title {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    order: 2;
  }
}