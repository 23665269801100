.cards__button {
  padding: 10px 20px;
  font-size: 20px;
  margin-bottom: 185px;
}

@media (max-width: 900px) {
  .cards__button {
    align-self: center;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 41px;
    order: 4;
  }
}