.accordion__question p {
  margin: 24px 0 24px 5px;
  padding: 0;
}
.accordion__question {
  font-family: "Uncut Sans Medium", Helvetica, Arial, sans-serif;
  display: flex;
  padding: 0;
  width: 800px;
  border-bottom: 1px solid #194D44;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@media (max-width: 768px) {
  .accordion__question {
    width: 320px;
    font-size: 16px;
    line-height: 24px;
  }
}