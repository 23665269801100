.review-card__title {
  font-family: "Uncut Sans Medium", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 20px 0 0;
  padding: 0;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
}

@media (max-width: 768px) {
  .review-card__title {
    font-size: 16px;
    line-height: 24px;
    max-width: 200px;
    margin: 0 6px 0 0;
  }
}