.section-1__title {
  grid-area: title;
  max-width: 420px;
  margin: 0;
  padding: 0;
  font-family: Steinbeck, Helvetica, Arial, sans-serif;
  font-size: 48px;
  line-height: 56px;
}

@media (max-width: 768px) {
  .section-1__title {
    margin-top: 212px;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    max-width: 254px;
  }
}