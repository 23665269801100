.page {
  background: #153935;
  font-family: "Uncut Sans", Helvetica, Arial, sans-serif;
  font-weight: 200;
  color: #E4D7CD;
}

@media (max-width: 768px) {
  .page {
    overflow-x: hidden;
  }
}