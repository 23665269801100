.cards {
  position: relative;
  display: flex;
  padding: 92px 0 0 112px;
  gap: 86px;
  align-items: center;
  flex-direction: row;
  height: 720px;
}

@media (max-width: 900px) {
  .cards {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: 0;
  }
}