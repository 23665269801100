.section-1__bg {
  background: url('./../../../images/section-1__bg.png') no-repeat top;
  background-size: 99.9vw;
  width: 99.99vw;
  height: calc(100% + 100px);
  position: absolute;
  top: -100px;
  left: 0;
  z-index: -1;
}

@media (max-width: 500px) {
  .section-1__bg {
    background: url('./../../../images/section-1__bg_mobile.png') no-repeat top;
    background-size: 99.99vw;
    width: 99.99vw;
    height: calc(100% + 68px);
    top: -68px;
  }
}